<template>
  <div class="detail dfc">
    <!-- <icy-header background="transparent" title=""></icy-header> -->
    <div class="detailBg">
      <img v-if="avatar" class="w100 h100 object-cover" :src="avatar" alt="" />
    </div>

    <div class="introduce dfc main-start">
      <div class="namePlateBox dfr main-start cross-center">
        <div v-if="name" class="fs40">{{ name }}</div>
      </div>
      <div class="intro-box dfr flex-wrap">
        <div class="introBox" v-for="(item, index) in introList" :key="index">
          <div class="intro fs24 fw400 fc666">
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="detail-title-box"
      v-for="(item, index) in detailList"
      :key="index"
    >
      <detail-s-title
        :icon="item.icon"
        :title="item.title"
        :content="item.content"
        :desc="item.desc"
        :scale="item.scale"
      ></detail-s-title>
    </div>
    <div
      style="margin-bottom:0.44rem"
      v-html="detailhtml"
      class="detail-intro fs28 fw400 fc666"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      avatar: "",
      name: "",
      introList: [],
      detailList: [
        {
          icon: require("../assets/images/detail-enterprise.png"),
          title: "所在企业",
          content: "",
          desc: "",
          scale: ""
        },
        {
          icon: require("../assets/images/detail-position.png"),
          title: "担任职务",
          content: ""
        },
        {
          icon: require("../assets/images/detail-brief.png"),
          title: "个人简介"
        }
      ],
      detailhtml: ""
    };
  },
  created() {
    this.$title("私董");
    window.scrollTo(0, 0);
    // 获取id
    this.id = parseInt(this.$route.query.id);
    // 调用获取私董详情信息
    this.getSiDongInfo();
  },
  mounted() {},
  methods: {
    async getSiDongInfo() {
      const resp = await this.$API
        .get("sidongDetail", { id: this.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        // 背景图
        this.avatar = resp.data.avatar;
        // 姓名
        this.name = resp.data.name;
        // 领域
        resp.data.field.forEach(item => {
          this.introList.push(item.name);
        });
        // 所在企业
        this.detailList[0].content = resp.data.company;
        if (resp.data.industry) {
          this.detailList[0].desc = resp.data.industry.name || "";
        }
        if (resp.data.scale) {
          this.detailList[0].scale = resp.data.scale.name || "";
        }
        // 担任职务
        if (resp.data.job) {
          this.detailList[1].content = resp.data.job || "";
        }
        // 个人简介
        let desc = "";
        if (resp.data.description.length) {
          resp.data.description.forEach(item => {
            if (item.type == 0) {
              desc += `<p style='margin: 10px 0; font-size:.28rem; color:#666'> ${item.text} </p>`;
            } else {
              desc += `<img src='${item.image}' style='margin: 10px 0;border-radius: 8px' />  `;
            }
          });
        }

        this.detailhtml = desc;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.detail {
  width: 7.5rem;
  background-color: #fff;
  .detailBg {
    width: 100%;
    height: 6.24rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .introduce {
    width: 100%;
    margin-top: -0.24rem;
    position: relative;
    background-color: #fff;
    border-radius: 0.24rem 0.24rem 0.12rem 0.12rem;
    .namePlateBox {
      margin-left: 0.36rem;
      margin-top: 0.36rem;
    }
    .intro-box {
      margin: 0.28rem 0.36rem 0 0.36rem;
      .introBox {
        background: #f6f6f6;
        border-radius: 0.04rem;
        margin-right: 0.12rem;
        margin-top: 0.12rem;
        .intro {
          padding: 0.12rem 0.18rem;
        }
      }
    }
  }
  .detail-title-box {
    margin-top: 0.6rem;
    margin-left: 0.36rem;
  }
  .detail-intro {
    width: 6.26rem;
    margin-left: 0.88rem;
  }
}
</style>
